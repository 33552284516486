import React from "react";
import { Typography, Box } from "@mui/material";
import Image from "@jy95/material-ui-image";
import doctorBurnout from "images/doctorBurnout.jpg";
import Strings from "config/config.json";

function ContentView() {
  const strings = Strings;
  return (
    <Box mt={4} px={2} maxWidth="90%" mx="auto">
      <Typography
        align="center"
        variant="overline"
        display="block"
        color="secondary"
        lineHeight={1}
        fontSize={18}
        fontWeight={700}
      >
        {strings.defaultText.overline}
      </Typography>
      <Typography
        align="center"
        variant="h1"
        lineHeight={1}
        fontWeight={500}
        paragraph
        fontSize={40}
      >
        {strings.title}
      </Typography>
      <Typography
        align="center"
        variant="subtitle"
        display="block"
        fontSize={18}
        paragraph
      >
        {strings.subtitle}
      </Typography>
      <Image src={doctorBurnout} />
    </Box>
  );
}

export default ContentView;
