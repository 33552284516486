import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import HubspotForm from "./components/HubspotForm";
import ContentPreview from "./components/ContentPreview";
import TagManager from "react-gtm-module";

function GateView(urlSafeTitle) {
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        pagePath: `access-content/${urlSafeTitle}`,
        pageTitle: "Welcome page with lead converter form",
      },
    });
  }, [urlSafeTitle]);

  return (
    <Grid
      container
      m="auto"
      maxWidth="lg"
      direction="row-reverse"
      alignItems="center"
    >
      <Grid item xs={12} md={6}>
        <ContentPreview />
      </Grid>
      <Grid item xs={12} md={6}>
        <HubspotForm />
      </Grid>
    </Grid>
  );
}

export default GateView;
