import React, { useEffect } from "react";
import { Typography, Box, Skeleton } from "@mui/material";
import HubspotForm from "react-hubspot-form";
import Strings from "config/config.json";
import DownloadIcon from "@mui/icons-material/Download";

function ContentView() {
  const strings = Strings;

  const sendEventToGtm = useEffect(() => {
    window.dataLayer.push({
      event: "event",
      eventProps: {
        category: "Form",
        action: "Form submited",
        label: `Ebook 01 ${strings.title}`,
        value: "Lead information",
      },
    });
  }, [strings.title]);

  return (
    <Box mb={2} sx={{ p: { xs: 3, md: 4 } }}>
      <Box mb={2}>
        <Box display="flex" alignItems="center">
          <DownloadIcon color="primary" sx={{ mr: 0.5 }} />
          <Typography sx={{ mb: 0 }} variant="h2">
            {strings.defaultText.title}
          </Typography>
        </Box>

        <Typography lineHeight="normal">
          {strings.defaultText.subtitle}
        </Typography>
      </Box>

      <HubspotForm
        portalId={strings.hubspotForm.portalId}
        formId={strings.hubspotForm.formId}
        onSubmit={() => sendEventToGtm}
        onReady={(form) => console.log("Form ready!")}
        loading={
          <Box minHeight={480}>
            <Skeleton animation="wave" height={60} />
            <Skeleton animation="wave" height={60} />
            <Skeleton animation="wave" height={60} />
            <Skeleton animation="wave" height={60} />
            <Skeleton animation="wave" height={60} />
            <Skeleton animation="wave" height={60} />
          </Box>
        }
      />

      <Box
        height={50}
        sx={{
          backgroundColor: "white",
          marginTop: -10,
          marginBottom: 14,
          position: "relative",
          zIndex: 999,
        }}
      >
        <Typography variant="body2" paragraph align="center">
          {strings.defaultText.enticement}
        </Typography>

        <Typography
          variant="body2"
          align="justify"
          sx={{
            fontSize: "10px",
            lineHeight: "1.5em",
            a: {
              color: "#232323",
              textDecoration: "none",
              cursor: "default",
            },
          }}
          dangerouslySetInnerHTML={{
            __html: strings.defaultText.privacyNotice,
          }}
        />
      </Box>
    </Box>
  );
}

export default ContentView;
